import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";
import Image from "@components/common/CloudinaryImage";

const Component = () => {
    return (
        <>
            <Layout>
                <Seo
                    title="Nuestra tienda"
                    description="Id occaecat nulla reprehenderit aute labore ex ipsum ad
                        aliquip ut aliquip qui consequat enim. Voluptate cillum
                        pariatur dolore in nostrud elit irure. Minim voluptate
                        ex aliqua occaecat non aliquip. Velit eu esse
                        exercitation sint irure nulla Lorem commodo."
                />

                <SmallBanner
                    title="Título"
                    subtitle="Subtítulo"
                    filename="bgs/bgStore2"
                    alt="Título"
                    height="h-screen"
                />

                <div className="container lg:px-0 py-20">
                    <Title>¿Qué ofrecemos?</Title>
                    <Text className="text-justify">
                        Descubre nuestra seleccion de cursos, libros, podcasts y audiolibros.
                    </Text>

                    <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
                        {data
                            ?.sort((a, b) => a.id.localeCompare(b.id))
                            .map(d => {
                                return (
                                    <div key={d.id} className="">
                                        <Link to={d.href} title={d.title}>
                                            <div
                                                className="bg-gray-100 rounded flex flex-col items-center justify-items-center p-10"
                                                style={{ aspectRatio: "1" }}
                                            >
                                                <div className="bg-gray-200 my-auto w-full h-full rounded-full overflow-hidden">
                                                    <Image alt={d.id} filename={d.image} />
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <h1 className="font-montserrat text-gray-600 font-semibold uppercase text-center ">
                                                    {d.title}
                                                </h1>
                                                <h2 className="font-montserrat text-gray-600 text-sm text-center mt-1">
                                                    {d.description}
                                                </h2>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Layout>
        </>
    );
};

const data = [
    {
        id: "locales",
        title: "Policías Locales",
        description:
            "Anim labore excepteur ipsum qui magna nostrud deserunt adipisicing deserunt enim nulla aliqua consequat proident.",
        image: "/sections/pl",
        href: "/tienda/policia-local",
    },
];

export default Component;
